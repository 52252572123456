import { useQuery } from '@tanstack/react-query';
import ClientFormService from '../services/ClientFormService';
import { ClientFormUserResponse } from '../models/ClientFormUser';
import useFetchClientUsers from './useFetchClientUsers';
import User from '../models/User';

function setFormUsers(clientFormUsers: ClientFormUserResponse[], clientUsers: User[]) {
  return clientFormUsers.map((userResponse) => {
    const user = clientUsers.find((u) => u.id === userResponse.userId);
    return user ? { ...userResponse, user } : userResponse;
  });
}

// Function to remove duplicates and map data structure
function uniqueAndMappedUsers(users: ClientFormUserResponse[]) {
  const found = new Set();
  return users
    .filter((clientFormUserResponse) => {
      if (!clientFormUserResponse.user) return false;
      const isNew = !found.has(clientFormUserResponse.user.id);
      if (isNew) found.add(clientFormUserResponse.user.id);
      return isNew;
    })
    .map((clientFormUserResponse) => ({
      ...clientFormUserResponse.user,
      role: clientFormUserResponse.role,
    }));
}

// Custom hook for fetching form users
const useFetchFormUsers = (activityId: string, clientFormUsers: ClientFormUserResponse[], isShared?: boolean) => {
  const { data: clientUsers = [] } = useFetchClientUsers();

  return useQuery({
    queryKey: ['formUsers', activityId, clientFormUsers],
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: clientUsers.length > 0,
    queryFn: async () => {
      if (!clientFormUsers.length) return [];

      const formUsers = setFormUsers(clientFormUsers, clientUsers);
      const responseData = isShared ? await ClientFormService.getFormUsers(activityId) : { data: formUsers };
      const sortedData = responseData.data.sort((a, b) => (a.formSectionId === b.formSectionId ? b.role - a.role : a.formSectionId ? 1 : -1));

      return uniqueAndMappedUsers(sortedData);
    },
  });
};
export default useFetchFormUsers;
